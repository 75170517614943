import React from 'react'
import Layout from '../components/layout'
import { useLanguages } from '../hooks/useLanguages'
import HubspotForm from "react-hubspot-form"
import AwardBadges from '../components/AwardBadges'
import Helmet from "react-helmet"
import RnRGCCCarousel from '../components/RnRGCCCarousel'

const RnRGCCCarouselDetails = [
    {
        slideShadow: '',
        slideImage: '2023/05/Infosys.webp',
        slideDescription: 'Infosys saw a 15% surge in employee engagement led to a more productive work environment.',
        ImageSize: 'scale-110'
    },
    {
        slideShadow: '',
        slideImage: '2024/05/Sun-Pharma.png',
        slideDescription: 'Sun Pharma elevated employee engagement by 12.5% using our Rewards & Recognition platform.',
        ImageSize: 'scale-110'
    },
    {
        slideShadow: '',
        slideImage: '2023/10/11.png',
        slideDescription: 'ACG saw a 66% increase in employee recognition using our behavioural science powered R&R platform.',
        ImageSize: 'scale-125'
    },
    {
        slideShadow: '',
        slideImage: '2024/07/LT-1-1.png',
        slideDescription: 'LTTS saw a 20% annual rise in rewards redemption since 2021 after starting to use our R&R platform.',
        ImageSize: 'scale-75'
    },
    {
        slideShadow: '',
        slideImage: '2024/05/TATA-Motors.png',
        slideDescription: 'Tata Motors saw a remarkable boost in R&R platform participation, with 2X growth in employee engagement.',
        ImageSize: 'scale-150'
    },
]

const Branding = ({ location }) => {
    const [currLanguage] = useLanguages()
  return (
    <Layout location={location} pageLanguage={currLanguage}>
         <Helmet>
          <title>Book a meeting</title>

          <meta name="description" content="Compare and pick a plan that fits your requirement and foster a culture of recognition at work." />
          <meta property="twitter:title" content="Book a meeting" />
          <meta name= "robots" content= "noindex" />
                  
          <meta property="twitter:description" content="Compare and pick a plan that fits your requirement and foster a culture of recognition at work." />
                  
          <meta property="og:type" content="website"/>
                  
          <meta property="og:url" content="https://www.vantagecircle.com/book-a-meeting-v1/"/>

          <meta property="og:site_name" content="Vantage Circle"/>
          <meta property="og:title" content="Book a meeting" />

          <meta property="og:description" content="Compare and pick a plan that fits your requirement and foster a culture of recognition at work" />
          <link rel="canonical" href="https://www.vantagecircle.com/book-a-meeting-v1/"></link>
      </Helmet>
        <section className='py-10 xl:py-16 px-5'>
            <div className='lg:flex justify-around px-5 max-w-7xl mx-auto'>
                <div className='lg:w-1/2 lg:mr-5'>
                    <h2 className='homepage-div-heading'>
                    Enhance <span className='text-orange'>employee engagement</span> holistically with our behavioral science-powered platform.
                    </h2>
                    <h2 className='homepage-small-heading my-7'>As we guide you through, we will delve into:</h2>
                    <ul className='orangebullets'>
                        <li className='my-2 text-base lg:text-lg'>A brief exploratory conversation to understand how Vantage Circle can help you drive desirable employee behaviours through recognition</li>
                        <li className='my-2 text-base lg:text-lg'>A thorough walk-through of our products, enriched with insights and relevant case studies</li>
                        <li className='my-2 text-base lg:text-lg'>A discussion aimed at aligning our solutions with your business objectives to chart the best course forward</li>
                    </ul>
                </div>
                <div className='lg:w-1/2 flex place-content-center lg:ml-5 pt-5 lg:pt-0' id="form">
                    <div className='rounded-xl bg-white shadow py-10 px-5 lg:w-5/6 mx-auto rnrForm-WhiteFields'>
                        <h2 className='homepage-small-heading text-center mx-auto mb-5'>
                            Book a personalized meeting for your unique business needs
                        </h2>
                        <div class="w-full flex justify-center items-center lato rewards team">
                                <div class="flex justify-center items-center">
                                    <HubspotForm
                                    portalId="6153052"
                                    formId="65783843-70fa-47df-9cd7-a5ad23b1ac19"
                                    onSubmit={() => console.log("Submit!")}
                                    onReady={form => console.log("Form ready!")}
                                    loading={<div>Loading...</div>}
                                    />
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </section> 

        <div className='bg-purple-100 w-full py-10 xl:py-16 px-5'>
            <div className='max-w-7xl mx-auto homepage-div-heading'>
                <h2 className='text-center mx-auto lg:mb-10'><span className='text-orange'>Trusted by</span> companies of all sizes, including Fortune 500</h2>
                <div className='c-logo-strip-2024'></div>
            </div>
        </div>

        <section className="w-full flex flex-col place-items-center py-10 xl:py-16 px-2 xl:px-0">
            <h2 className="lg:pr-3 w-full md:w-4/6 xl:max-w-5xl mx-auto homepage-section-heading text-center px-2">
                Unlock your employees’ full potential with <span className="text-orange">comprehensive solutions</span> for Business Growth
            </h2>
            <p className="text-gray-500 text-base md:text-lg xl:text-2xl py-3 text-center px-3 hidden">
                Our SaaS products are powerful alone but stronger together
            </p>
            <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-6 justify-center items-center max-w-7xl mx-auto my-2 xl:pt-6 px-6 xl:px-0 2xl:px-6">
                {/* Product Block 1 */}
                <div className="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                <img decoding="async" loading="lazy" className="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-HomeRewards-3.webp" alt="Homepage-Rewards-1" width="300" height="231" />
                <h3 className="lato w-5/6 mx-auto text-center">Employee Rewards and Recognition platform</h3>
                
                </div>

                {/* Product Block 2 */}
                <div className="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                <img decoding="async" loading="lazy" className="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-HomeVfit-3.webp" alt="Homepage-vfit-1" width="300" height="231" />
                <h3 className="lato w-5/6 mx-auto text-center">Employee Wellness <br /> platform</h3>
                
                </div>

                {/* Product Block 3 */}
                <div className="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                <img decoding="async" loading="lazy" className="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-HomePulse-3.webp" alt="Homepage-pulse-1" width="300" height="231" />
                <h3 className="lato w-5/6 mx-auto text-center">Employee Feedback <br /> platform</h3>
                
                </div>

                {/* Product Block 4 */}
                <div className="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                <img decoding="async" loading="lazy" className="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-HomePerks-3.webp" alt="Homepage-perks-1" width="300" height="231" />
                <h3 className="lato w-5/6 mx-auto text-center">Corporate Discounts <br /> platform</h3>
                
                </div>
            </div>
        </section>

        <section className='bg-indigo-100 w-full py-10 xl:py-16 xl:pb-0 px-5' id='successStories'>
            <div className='max-w-7xl mx-auto lg:px-5'>
                <div className='lg:flex'>
                    <div className='lg:w-7/12 lg:pl-5 lg:my-auto'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2024/07/Paid-Landing-Wipro-Logo-1.png" media="(min-width: 1200px)" width="200"/>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_200/q_auto/gatsbycms/uploads/2024/07/Paid-Landing-Wipro-Logo-1.png" media="(min-width: 640px)" width="150"/>
                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/q_auto/gatsbycms/uploads/2024/07/Paid-Landing-Wipro-Logo-1.png" alt="A description of the image." width="150"/>
                        </picture>
                        <h2 className='homepage-div-heading text-purple-100 my-7'>Wipro achieved <span className='text-orange'>Cultural Transformation</span> through innovative <span className="hero-text">R&R platform</span></h2>
                        <p className='text-purple-100'>In the last fiscal year, over 57% of Wipro's employees received recognition, equivalent to one every 1.2 minutes. Wipro saw a 97.5% increase in appreciation since 2022, leading to improved overall productivity.</p>
                    </div>
                    <div className='lg:w-6/12 lg:ml-5 lg:px-5 pt-7 lg:pt-0 flex lg:justify-end'>
                        <div className='rounded-xl border border-gray-darklight grid justify-center px-5 lg:px-12 py-8 lg:pt-8 lg:pb-0 lg:min-h-450px h-auto lg:w-9/12'>
                            <p className='italic text-purple-100 text-sm lg:text-base leading-7'>
                            At Wipro, we firmly believe that when appreciation becomes a habit, excellence becomes the norm. Our recognition program, Winners' Circle, has been instrumental in embedding recognition into our daily work life and culture. Over 57% of our associates received recognition in the last fiscal year. Considering our size, this coverage attests to the strong performance of our recognition program.
                            </p>
                            <div className='flex justify-end w-full relative lg:h-28 pt-4 lg:pt-0'>
                                <div className='flex lg:absolute -right-24 bg-indigo-100'>
                                    <div className='grid my-auto'>
                                        <h2 className='homepage-mini-heading text-purple-100 text-right m-0'>
                                        Sunita Cherian
                                        </h2>
                                        <p className='text-right text-purple-100 text-right text-base m-0'>
                                        Senior VP and Chief Culture Officer,<br/>Wipro
                                        </p>
                                    </div>                                  
                                    <div className='rounded-full border-gray-darklight border p-2 ml-3 my-auto'>
                                        <picture>
                                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2024/07/Mask-group-1.png" media="(min-width: 1200px)" width="80"/>
                                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_200/q_auto/gatsbycms/uploads/2024/07/Mask-group-1.png" media="(min-width: 640px)" width="80"/>
                                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/q_auto/gatsbycms/uploads/2024/07/Mask-group-1.png" alt="A description of the image." width="80"/>
                                        </picture>
                                    </div>
                                </div>     
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' mt-10 lg:mt-20 rnrCarousel'>
                    <RnRGCCCarousel RnRSlideDetails = {RnRGCCCarouselDetails} />
                </div>
            </div>
        </section>

        {/* <section className='bg-indigo-100 w-full py-10 xl:py-16 xl:pb-0 px-5'>
            <div className='max-w-7xl mx-auto lg:px-5'>
                <div className='lg:flex'>
                    <div className='lg:w-1/2 lg:mr-3'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2024/07/Paid-Landing-Wipro-Logo-1.png" media="(min-width: 1200px)" width="200"/>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_200/q_auto/gatsbycms/uploads/2024/07/Paid-Landing-Wipro-Logo-1.png" media="(min-width: 640px)" width="150"/>
                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/q_auto/gatsbycms/uploads/2024/07/Paid-Landing-Wipro-Logo-1.png" alt="A description of the image." width="150"/>
                        </picture>
                        <h2 className='homepage-div-heading text-purple-100 my-7'>Wipro achieved <span className='text-orange'>Cultural Transformation</span> through innovative R&R platform</h2>
                        <p className='text-purple-100'>In the last fiscal year, over 57% of Wipro's employees received recognition, equivalent to one every 1.2 minutes. Wipro saw a 97.5% increase in appreciation since 2022, leading to improved overall productivity.</p>
                    </div>
                    <div className='lg:w-1/2 lg:ml-5 lg:px-5 pt-7 lg:pt-0 flex lg:justify-end'>
                        <div className='rounded-xl border border-gray-darklight grid justify-center px-5 lg:px-12 py-8 lg:pt-8 lg:pb-0 lg:min-h-450px h-auto lg:w-9/12'>
                            <p className='italic text-purple-100 text-sm lg:text-base leading-7'>
                            At Wipro, we firmly believe that when appreciation becomes a habit, excellence becomes the norm. Our recognition program, Winners' Circle, has been instrumental in embedding recognition into our daily work life and culture. Over 57% of our associates received recognition in the last fiscal year. Considering our size, this coverage attests to the strong performance of our recognition program.
                            </p>
                            <div className='flex justify-end w-full relative lg:h-28 pt-4 lg:pt-0'>
                                <div className='flex lg:absolute -right-24 bg-indigo-100'>
                                    <div className='grid my-auto'>
                                        <h2 className='homepage-mini-heading text-purple-100 text-right m-0'>
                                        Sunita Cherian
                                        </h2>
                                        <p className='text-right text-purple-100 text-right text-base m-0'>
                                        Senior VP and Chief Culture Officer,<br/>Wipro
                                        </p>
                                    </div>                                  
                                    <div className='rounded-full border-gray-darklight border p-2 ml-3 my-auto'>
                                        <picture>
                                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2024/07/Mask-group-1.png" media="(min-width: 1200px)" width="80"/>
                                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_200/q_auto/gatsbycms/uploads/2024/07/Mask-group-1.png" media="(min-width: 640px)" width="80"/>
                                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/q_auto/gatsbycms/uploads/2024/07/Mask-group-1.png" alt="A description of the image." width="80"/>
                                        </picture>
                                    </div>
                                </div>     
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' mt-10 lg:mt-20 rnrCarousel'>
                    <RnRGCCCarousel RnRSlideDetails = {RnRGCCCarouselDetails} />
                </div>
            </div>
        </section> */}

        <section className='px-5' id="BadgesSection"></section>

    </Layout>
  )
}

export default Branding